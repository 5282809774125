<template>
    <MasterDetail
      ref="masterDetail"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :opts="opts"
      :cols="cols"
      :hasNewButton="false"
      :canEdit="false"
      :canDelete="false"
      :actionBarButtons="actionBarButtons"
      :contextOptions="contextMenu"
      :customResource="customResource">
    </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
//import FormModal from '@/components/form-modal.vue';

export default {
    // props: {},
    components: {
        MasterDetail,
        // FormModal,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
          opts: {},
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      actionBarButtons() {
        const actionBarButtons = [];
        const that = this;
        actionBarButtons.push({
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: true,
          action(){
            that.$router.push({ name: "projetos-captacao-cadastro", query: { clientId: that.clientId }});
          }
        });
        return actionBarButtons.filter(({ show }) => show);
      },
      contextMenu() {
        const contextMenuButtons = [];
        const that = this;
        contextMenuButtons.push(
          {
            name: "Rubrica descrição",
            show: true,
            cb(row){
              that.$router.push({ name: "descricao-dispendios", query: { projetoId: row.id }});
            }
          },
          {
            name: "Lançamentos de despesas",
            show: true,
            cb(row){
              that.$router.push({ name: "demais-dispendios", query: { projetoId: row.id }});
            }
          },
          {
            name: "sep",
            show: {
              get() {
                return contextMenuButtons.slice(0, 3).some(({ show }) => show) && contextMenuButtons.slice(4).some(({ show }) => show);
              }
            },
          },
          {
            name: "Dispêndios de RH",
            show: true,
            cb(row){
              that.$router.push({ name: "timesheets-captacao", query: { projetoId: row.id }});
            }
          },
          {
            name: "sep",
            show: {
              get() {
                return contextMenuButtons.slice(0, 3).some(({ show }) => show) && contextMenuButtons.slice(4).some(({ show }) => show);
              }
            },
          },
          {
            name: "Parametrização do orçamento",
            show: true,
            cb(){
              alert('Em desenvolvimento...');
              return;
            }
          },
          {
            name: 'Parcelas RTAs',
            show: true,
            cb(){
              alert('Em desenvolimento...');
              return;
            }
          },
          {
            name: "Orçamento detalhado",
            show: true,
            cb(){
              alert('Em desenvolimento...');
              return;
            }
          },
          {
            name: "Comprovação do orçamento",
            show: true,
            cb(){
              alert('Em desenvolimento...');
              return;
            }
          },
          {
            name: "sep",
            show: {
              get() {
                return contextMenuButtons.slice(0, 3).some(({ show }) => show) && contextMenuButtons.slice(4).some(({ show }) => show);
              }
            },
          },
          {
            name: "Editar",
            show: true,
            cb(row){
              that.$router.push({ name: "projetos-captacao-cadastro", query: { clientId: that.clientId, projetoId: row.id }});
            }
          },
          {
            name: "Excluir",
            show: true,
            cb(row){
              that.excluirProjeto(row.id);
            }
          },

      );

        return contextMenuButtons.filter(({ show }) => show);
      },
      resourceUrl() {
        return this.apiResource(`/v1/captacao/projetos/${this.clientId}`);
      },
      customResource() {
        const resource = this.resourceUrl;
        return {
          ...resource,
          get(...args) {
            return resource.get(args).then((response) => {
              return response;
            });
          }
        }
      },
      cols() {
        return [
        {
            key: 'id',
            name: 'Código',
            type: this.$fieldTypes.TEXT,
            hideInTable: true,
            hideInform: true,
          },
          {
            key: 'titulo',
            name: 'Titulo projeto',
            type: this.$fieldTypes.TEXT,
          },
          {
            key: 'nro_contrato',
            name: 'Nº contrato',
            type: this.$fieldTypes.TEXT,
          },
          {
            key: 'status',
            name: 'Status',
            type: this.$fieldTypes.TEXT,
          },
          {
            key: 'percentual_comprovacao',
            name: '% Comprovação',
            type: this.$fieldTypes.TEXT,
          },
          {
            key: 'data_inicio',
            name: 'Data início',
            type: this.$fieldTypes.DATE,
          },
          {
            key: 'data_fim',
            name: 'Data fim',
            type: this.$fieldTypes.DATE,
          },
          {
            key: 'responsavel',
            name: 'Responsável',
            type: this.$fieldTypes.TEXT,
          },
        ]
      }
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
      excluirProjeto(idProjeto) {
        const resource = this.resourceUrl;
        return resource.delete(idProjeto).then((response) => {
          this.doLoad();
          return response;
        });
      }
     }
}
</script>

<style>

</style>
